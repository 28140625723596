<template>
  <el-row class="table">
    <el-select v-model="searchtype" placeholder="请选择类型" @change="getSchoolValue" class="tb-smselect">
      <el-option v-for="item in searchTypeArray" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <el-input
      v-model.trim="userNameValue"
      :placeholder="placeholder"
      @keyup.enter.native="searchData"
      class="tb-sm-input"
    ></el-input>
    <el-button type="primary" size="medium" @click="searchData" class="tb-button">搜索</el-button>
    <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
    >
      <template>
        <el-table-column label="姓名" align="center" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.creatorInfo.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="学号" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.creatorInfo.loginName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="批阅结果" align="center">
          <template slot-scope="scope">
            <span v-text="pythonText(scope.row.isCorrect)" :style="'color' + ':' + pythonColor(scope.row.isCorrect)"></span>
          </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.createTime.substring(0,19)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button
              style="margin-right: 10px;"
              size="small"
              type="primary"
              plain
              @click="classManage(scope.row,scope.$index)"
            >查 看</el-button>
            <el-button
              style="margin-right: 10px;"
              size="small"
              type="primary"
              plain
              @click="readOver(scope.row,scope.$index)"
            >批 阅</el-button>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- python 批阅 -->
    <python-read :show="pythonDialog" @close="pythonDialog=false;pythonData=null" :pythonData="pythonData" @updateView="searchData"></python-read>
  </el-row>
</template>

<script>
  import { getSubmitList} from '@/api/senate/class'
  import PythonRead from '@/components/senate/python-read'
  import pythonText from '@/mixins/getStateText'
  import pythonColor from '@/mixins/getStateColor'
  export default {
    components: { PythonRead },
    mixins: [pythonText,pythonColor],
    data () {
      return {
        searchTypeArray:[
          {
            value:1,
            label:'姓名'
          },
          {
            value:2,
            label:'学号'
          },
        ],
        userNameValue: '',
        searchtype: '姓名',
        placeholder: '请输入姓名',
        dataList: [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        pythonDialog: false,
        pythonData: null,
        taskId: 0 ,
      }
    },

    created () {
      this.taskId = this.$route.query.taskId
      this.getSubmitList()
      // var lett = this;
      // document.onkeydown = function(e) {
      //   var key = window.event.keyCode;
      //   if (key == 13) {
      //     lett.searchData();
      //   }
      // }
    },
    methods: {
      reset(){
        this.userNameValue = ''
        this.searchtype = '姓名'
        this.placeholder = '请输入姓名'
      },
      // 选择校区
      getSchoolValue (val) {
       if(val === 1) {
         this.placeholder = '请输入姓名'
       }else if(val === 2) {
         this.placeholder = '请输入学号'
       }
      },
      searchData() {
        this.pagination.currentPage = 1
        this.getSubmitList()
      },
      // 获取任务python列表
      async getSubmitList () {
        const res = await getSubmitList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          userName:(this.searchtype === '姓名' || this.searchtype === 1) ? this.userNameValue:'',
          userLoginName:this.searchtype ===  2 ? this.userNameValue:'',
          taskId: this.taskId,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },

      // 查看python代码
      classManage (row,index) {
        let titlethemb = row.taskInfo.title

        let routeData = this.$router.resolve({
          path: '/senate/preview-pythonfile',
          query: {
            title: titlethemb,
            code: this.dataList[index].code
          }
        })
        window.open(routeData.href, '_blank')
      },

      // 批阅-dialog打开
      readOver (row, index) {
        this.pythonData = row
        this.pythonDialog = true
      },

      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.getSubmitList()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.getSubmitList()
      },

      lookGoCode (row, index) {
        // console.log('lookGoCode', row, index)
        let routeData = this.$router.resolve({
          path: '/research/preview-task',
          query: {
            taskID: row.taskId,
            code: row.code
          }
        })
        window.open(routeData.href, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
</style>
